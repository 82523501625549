import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header';
import UnderHeader from './components/UnderHeader';
import CourseList from './main/CourseList';
import './App.css';
import { auth } from '../src/config/firebaseConfig';
import { signInAnonymously } from 'firebase/auth';
import NotFound from './components/NotFound';
import GeneralCourseListPage from './generalSubject/GeneralCourseListPage';
import PrivacyPolicy from './PrivacyPolicy';
import MobileHeader from './components/MobileHeader';
import { CourseProvider, GeneralCourseProvider } from './config/context';
import GeneralUnderHeader from './generalSubject/GeneralUnderHeader';
import GeneralHeader from './generalSubject/GeneralHeader';
import GeneralMobileHeader from './generalSubject/GeneralMobileHeader';
import RulePage from './Rule';
import FirstPage from './FirstPage';
import SecondPage from './SecondPage';
import HelpPage from './components/Help';
import MobileGeneralCoursePage from './generalSubject/MobileGeneralCourse';
import MobileCoursePage from './main/MobileCoursePage';
// import RootPage from './components/RootPage';
import GuideMobileApp from './main/GuideMobileApp';
const App = () => {
  const [isFirstVisit, setIsFirstVisit] = useState(null);

  const [loading, setLoading] = useState(true)

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 800);

  useEffect(() => {
    const allowedHosts = process.env.REACT_APP_ALLOWED_HOST.split(',');
    const currentHost = window.location.hostname;

    if (!allowedHosts.includes(currentHost)) {
      alert('無効なホスト名からアクセスされています。');
      window.location.href = `https://${allowedHosts[0]}`;
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize); // 画面サイズ変更時の処理を追加

    return () => {
      window.removeEventListener('resize', handleResize); // クリーンアップ
    };
  }, []);

  useEffect(() => {
    const handleLogin = async () => {
      try {
        await signInAnonymously(auth);
        const firstVisit = localStorage.getItem('first') === 'true';
        console.log(firstVisit);
        setIsFirstVisit(firstVisit);
      } catch (error) {

      } finally {
        setLoading(false);
      }
    };

    handleLogin();
  }, []);

  if (loading) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh">
        <CircularProgress />
        <Typography mt={2}>読み込み中です</Typography>
      </Box>
    );
  }

  return (

    <Router>
      <Box sx={{ padding: '0 40px' }} className="mobile-layout">
        <Routes>
          <Route path="/courses" element={
            <>
              <CourseProvider>
                {isMobile ? (
                  <MobileHeader />
                ) : (
                  <>
                    <Header />
                    <UnderHeader />
                  </>
                )}
                <CourseList
                />
              </CourseProvider>
            </>
          } />

          <Route path="/course/:id" element={
            <>
              <CourseProvider>
                <MobileCoursePage />
              </CourseProvider></>
          } />

          <Route path="/general-course/:id" element={
            <>
              <GeneralCourseProvider>
                <MobileGeneralCoursePage />
              </GeneralCourseProvider>
            </>
          } />

          <Route path="/general-courses" element={
            <GeneralCourseProvider>
              {isMobile ? (
                <GeneralMobileHeader />
              ) : (
                <>
                  <GeneralHeader />
                  <GeneralUnderHeader />
                </>
              )}

              <GeneralCourseListPage />
            </GeneralCourseProvider>
          } />

          <Route path="*" element={<NotFound />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/term" element={<RulePage />} />
          <Route path="/rule" element={<RulePage />} />
          <Route path="/first" element={<FirstPage />} />
          <Route path="/warning" element={
            <CourseProvider>
              <SecondPage />
            </CourseProvider>
          } />
          <Route path="/help" element={<HelpPage />} />
          <Route path="/" element={
            isFirstVisit ? <Navigate to="/first" /> :<Navigate to="/general-courses" />
          } />
          {/* <Route path="/" element={<RootPage />} /> */}

          <Route path="/app-guide" element={
            <CourseProvider>
              <GuideMobileApp />
            </CourseProvider>
          } />

        </Routes>
      </Box>
    </Router>

  );
};

export default App;