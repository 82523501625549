import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { CourseContext } from './config/context';
import { useCourses } from './config/context';

const SecondPage = () => {
    const navigate = useNavigate();
    const { currentOS } = useCourses(CourseContext);

    const handleStart = () => {
        localStorage.setItem('first', 'true');
        navigate('/general-courses');
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vH" >
            <Typography
                sx={{
                    fontWeight: 600,
                    color: "purple",
                }}
                variant="h4"
            >注意
            </Typography>
            <Typography
                sx={{
                    fontSize: 18
                }}
                variant="body1"
                mt={2}
            >
                少し古い科目情報も含まれているため、履修登録の際は大学が発表している最新の情報を必ず確認してください
            </Typography>

            {currentOS === "Android" ? (
                <>
                    <Typography sx={{ mt: 3 }}>\おすすめ/</Typography>
                    <Button
                        variant="contained"
                        onClick={() => {
                            switch (currentOS) {
                                case "Android":
                                    window.open('https://play.google.com/store/apps/details?id=com.milicone.dorakutan', '_blank');
                                    break;
                                default:
                                    break;
                            }
                        }}
                        to="/"
                        sx={{
                            background: "#8b008b",
                            fontWeight: 600,
                            fontSize: 18,
                            width: 200,

                            borderRadius: 10,
                            '&:hover': {
                                backgroundColor: '#660066', // ホバーカラーを指定
                            }
                        }}
                    >
                        アプリを使う
                    </Button>
                    <Typography sx={{ mt: 1 }}>または</Typography>
                </>
            ) : null

            }

            <Button
                onClick={handleStart}
                to="/"
                sx={{
                    background: "#8b008b",
                    fontWeight: 600,
                    fontSize: 18,
                    width: 200,
                    color: "white",
                    mt: 2,

                    borderRadius: 10,
                    '&:hover': {
                        backgroundColor: '#660066', // ホバーカラーを指定
                    }
                }}

            >
               { (currentOS === "Android") ?  "Webで続ける" : "次へ"} 
            </Button>

        </Box>
    );
};

export default SecondPage;