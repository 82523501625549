import { Box, Typography, Select, MenuItem, Button } from '@mui/material';
import { useGeneralCourses } from '../config/context';
// import { useNavigate } from 'react-router-dom';
import React from 'react';
import { useNavigate } from 'react-router-dom';


const GeneralMobileHeader = () => {

    const {
        selectedUniversity,
        selectedField,
        setSelectedField,
        term,
        setTerm

    } = useGeneralCourses();

    const isDoshisha = selectedUniversity === '同志社';
    const mainColor = isDoshisha ? '#800080' : '#9b59b6';
    const navigate = useNavigate();
    // const hoverColor = isDoshisha ? '#b83fb3' : '#b3a1c7';
    // const [legendOpen, setLegendOpen] = useState(false);

    // const handleOpenLegend = () => {
    //     setLegendOpen(true);
    // };

    // const handleCloseLegend = () => {
    //     setLegendOpen(false);
    // };


    const handleSignUpClick = () => {
        navigate('/courses'); 
    };

    const categories = [
        '同志社科目',
        'キャリア形成支援科目',
        '国際教養科目',
        'クリエイティブジャパン科目',
        '人文科学系科目',
        '社会科学系科目',
        '自然人間科学系科目',
        '複合領域科目',
        'プロジェクト科目',
        '保健体育科目',
    ];

    const handleFieldChange = async (event) => {
        const field = event.target.value;
        if (field !== selectedField) {
            setSelectedField(field);
        }
    };

    const handleTermChange = async (newTerm) => {
        if (newTerm !== term) {
            setTerm(newTerm);
        }
    };


    return (
        <Box
            sx={{
                mt: 2,
            }}
            display="flex"
            flexDirection="column"
            alignItems="center">
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mb:3
                }}
            >
                <Typography
                    sx={{
                        textAlign: 'left',
                        fontWeight: 600,
                        fontSize: 16,
                    }}
                >
                    (非公式) {selectedUniversity}楽単まとめ
                </Typography>

                <Button
                    variant="contained"
                    sx={{
                        width: 150,
                        height: 46,
                        fontSize: '16px',
                        fontWeight: 600,
                        backgroundColor: mainColor,
                        color: 'white',
                        borderRadius: '25px',
                        ml:2,
                        textTransform: 'none',
                        '&:hover': {
                            backgroundColor: '#6a006a', // ホバー時の色を必要に応じて調整
                        },
                    }}
                    onClick={handleSignUpClick}
                >
                    専門科目を見る
                </Button>
            </Box>

            <Box
                width="99%"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                p={2}
                bgcolor="#ffffff"
                boxShadow={2}
                borderRadius="10px"
            >
                {/* <Box
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    mb={2}
                >
                    <Box sx={{
                        position: 'relative',
                        width: '90%',
                        height: '37px',
                        backgroundColor: '#ececec',
                        borderRadius: '20px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        px: '5px'
                    }}>
                        <Box
                            sx={{
                                position: 'absolute',
                                width: '49%',
                                height: '80%',
                                backgroundColor: mainColor,
                                borderRadius: '20px',
                                transition: 'transform 0.3s',
                                zIndex: 0,
                                transform: isDoshisha ? 'translateX(0)' : 'translateX(100%)',
                            }}
                            onClick={() => setSelectedUniversity(isDoshisha ? '同女' : '同志社')}
                        ></Box>

                        <Box
                            sx={{
                                zIndex: 1,
                                backgroundColor: 'transparent',
                                border: 'none',
                                cursor: 'pointer',
                                fontSize: '16px',
                                fontWeight: 700,
                                width: '50%',
                                textAlign: 'center',
                                userSelect: 'none',
                                color: isDoshisha ? 'white' : 'black',
                            }}
                            onClick={() => setSelectedUniversity('同志社')}
                        >
                            同志社
                        </Box>
                        <Box
                            sx={{
                                zIndex: 1,
                                backgroundColor: 'transparent',
                                border: 'none',
                                cursor: 'pointer',
                                fontSize: '16px',
                                fontWeight: 700,
                                width: '50%',
                                textAlign: 'center',
                                userSelect: 'none',
                                color: !isDoshisha ? 'white' : 'black',
                            }}
                            onClick={() => setSelectedUniversity('同女')}
                        >
                            同女
                        </Box>
                    </Box>
                </Box> */}

                <Box display="flex" alignItems="center" mx={2} mb={2}>
                    <Typography variant="body1">科目分類</Typography>
                    <Select
                        value={selectedField}
                        onChange={handleFieldChange}
                        sx={{
                            fontSize: 14,
                            borderRadius: '25px',
                            backgroundColor: '#E0E0E0',
                            height: '30px',
                            marginLeft: '10px'
                        }}
                    >
                        {Array.isArray(categories) && categories.map((category) => (
                            <MenuItem key={category} value={category}>
                                {category}
                            </MenuItem>
                        ))}

                    </Select>

                    {/* <Box display="flex" alignItems="center">
                        <Button variant="outlined" onClick={handleOpenLegend} sx={{ ml: 2 }}>
                            凡例
                        </Button>
                    </Box> */}

                </Box>

                <Box
                    sx={{
                        width: "94%"
                    }}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Box
                        sx={{
                            position: 'relative',
                            width: '90%',
                            height: '40px',
                            backgroundColor: '#ececec',
                            borderRadius: '20px',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            padding: '0 10px',
                        }}
                    >
                        <Box
                            sx={{
                                position: 'absolute',
                                width: '24%',
                                height: '80%',
                                backgroundColor: mainColor,
                                borderRadius: '20px',
                                transition: 'transform 0.3s',
                                zIndex: 0,
                                transform:
                                    term === '春学期'
                                        ? 'translateX(0%)'
                                        : term === '秋学期'
                                            ? 'translateX(100%)'
                                            : term === '通年'
                                                ? 'translateX(200%)'
                                                : 'translateX(300%)',
                            }}
                            onClick={() =>
                                handleTermChange(
                                    term === '春学期'
                                        ? '秋学期'
                                        : term === '秋学期'
                                            ? '通年'
                                            : term === '通年'
                                                ? '全部'
                                                : '春学期'
                                )
                            }
                        ></Box>
                        <Box
                            sx={{
                                zIndex: 1,
                                backgroundColor: 'transparent',
                                border: 'none',
                                cursor: 'pointer',
                                fontSize: '16px',
                                fontWeight: 700,
                                width: '25%',
                                textAlign: 'center',
                                color: term === '春学期' ? 'white' : 'black',
                            }}
                            onClick={() => handleTermChange('春学期')}
                        >
                            春学期
                        </Box>
                        <Box
                            sx={{
                                zIndex: 1,
                                backgroundColor: 'transparent',
                                border: 'none',
                                cursor: 'pointer',
                                fontSize: '16px',
                                fontWeight: 700,
                                width: '25%',
                                textAlign: 'center',
                                color: term === '秋学期' ? 'white' : 'black',
                            }}
                            onClick={() => handleTermChange('秋学期')}
                        >
                            秋学期
                        </Box>
                        <Box
                            sx={{
                                zIndex: 1,
                                backgroundColor: 'transparent',
                                border: 'none',
                                cursor: 'pointer',
                                fontSize: '16px',
                                fontWeight: 700,
                                width: '25%',
                                textAlign: 'center',
                                color: term === '通年' ? 'white' : 'black',
                            }}
                            onClick={() => handleTermChange('通年')}
                        >
                            通年
                        </Box>
                        <Box
                            sx={{
                                zIndex: 1,
                                backgroundColor: 'transparent',
                                border: 'none',
                                cursor: 'pointer',
                                fontSize: '16px',
                                fontWeight: 700,
                                width: '25%',
                                textAlign: 'center',
                                color: term === '全部' ? 'white' : 'black',
                            }}
                            onClick={() => handleTermChange('全部')}
                        >
                            全部
                        </Box>
                    </Box>
                </Box>
                {(term === "通年") ? (
                    <Box>
                        <Typography sx={{
                            color: 'red'
                        }}>
                            同じ科目で春と秋に別々に開講される科目も含まれます
                        </Typography>
                    </Box>
                ) : null}
                {/* <Typography sx={{ fontSize: 16 }} variant="body1" mt={2}>専門科目は9月中旬にスタートします</Typography> */}

                {/* <Box  display="flex" justifyContent="center" alignItems="center">
                    <Modal
                        open={legendOpen}
                        onClose={handleCloseLegend}
                        aria-labelledby="legend-modal-title"
                        aria-describedby="legend-modal-description"
                        sx={{
                            width: '90%'
                        }}
                    >
                        <Box
                            sx={{
                                bgcolor: 'background.paper',
                                border: '2px solid #000',
                                boxShadow: 24,
                                p: 4,
                            }}
                        >
                            <Typography id="legend-modal-title" variant="h6" component="h2">
                                科目分類凡例
                            </Typography>
                            <Typography id="legend-modal-description" sx={{ mt: 2 }}>
                                <strong>人文科学科目：</strong>宗教・哲学・倫理・論理・芸術・歴史・文学<br />
                                <strong>社会科学科目：</strong>法学・政治・経済・商学<br />
                                <strong>自然人間科学科目：</strong>DS・AI・物理数学化学・地球宇宙・生命・環境・自然・心理<br />
                            </Typography>
                        </Box>
                    </Modal>
                </Box> */}
            </Box>


        </Box>
    );
};

export default GeneralMobileHeader;